import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { mixed, object, string } from 'yup';
import { supabase } from '../../../../../data/supabase';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import { useAuth } from '../../../../../shared/context/useAuth';
import { addAvatar } from '../../../../../store/reducers/avatar';
import { openModal } from '../../../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../../../utils/globalConstantUtil';
import useProfile from '../../../hooks/UseProfile';
import './index.css';

const genderOptions = [
  { name: 'Selecione', value: '' },
  { name: 'Masculino', value: 'M' },
  { name: 'Feminino', value: 'F' },
  { name: 'Outros', value: 'N' },
];

const schemaForm = object({
  name: string().required('O campo é obrigatório.'),
  email: string().email('E-mail inválido.').required('O campo é obrigatório.'),
  date_of_birth: string().required('O campo é obrigatório.'),
  gender: mixed()
    .required('O campo é obrigatório.')
    .oneOf(['M', 'F', 'N'], 'Selecione uma opção válida.'),
  mobile_number: string().required('O campo é obrigatório.'),
  telephone_number: string().optional(),
  country: string().required('O campo é obrigatório.'),
  state: string().required('O campo é obrigatório.'),
  city: string().required('O campo é obrigatório.'),
  neighborhood: string().optional(),
  street_name: string().required('O campo é obrigatório.'),
  typeSession: mixed()
  .required('O campo é obrigatório.'), 
  house_number: string().optional(),
  zip_code: string().required('O campo é obrigatório.'),
  about: string().required('O campo é obrigatório.'),
  certified: string().optional(),
});

function Profile() {
  const {
    specialist,
    specialties,
    media,
    userId,
    updateProfile,
    uploadFile,
    getMedia,
  } = useProfile();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange",resolver: yupResolver(schemaForm) });
  const fileInputRef = useRef();
  const [avatar, setAvatar] = useState();
  const dispatch = useDispatch();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTypeSession, setSelectedTypeSession] = useState([]);
  const { user } = useAuth();
  console.log('getValues', getValues('select'));
  
  const getAvatar = async () => {
    const { data, error } = await supabase.storage
      .from('uploads')
      .list(`${user.id}/avatar/`, {
        limit: 1,
        offset: 0,
        sortBy: { column: 'updated_at', order: 'desc' },
      });

    if (data && data?.length > 0) {
      setAvatar(data[0].name);
    }
  };

  useEffect(() => {
    getMedia();
    getAvatar();
    setSelectedSkills(specialist[0]?.skills);
    setSelectedTypeSession(specialist[0]?.typeSession)
    // reset form with specialist data
    reset(...specialist);
  }, [specialist]);

  const isGoogle = user.identities.filter((item) => {
    return item.provider !== 'email';
  });
  const handleFormSubmit = (data) => {
    data.skills = selectedSkills;
    updateProfile(data);
  };

  const handleUploadCertified = (e) => {
    uploadFile(e);
  };

  const handleUploadAvatar = async (event) => {
    const file = event.target.files[0];
    // setAvatar(file);
    const { data } = await supabase.storage
      .from('uploads')
      .upload(`${specialist[0]?.id_user}/avatar/${file?.name}`, file, {
        upsert: true,
      });

    if (data) {
      setAvatar(data?.path?.split('/')[2]);
      dispatch(addAvatar({ data }));
      toast.success('Foto de perfil atualizado');
    }
  };

  const handleProfileDisable = (event) => {
    if (!event.target.checked) {
      event.target.checked = true;
      dispatch(
        openModal({
          title: 'DESATIVAR PERFIL',
          bodyType: MODAL_BODY_TYPES.VACATION,
          extraObject: {
            message: 'Qual motivo da desativação do Perfil?',
          },
        }),
      );
    }
  };

  console.log('errors', errors);
  
    

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Perfil do Especialista"
        subtitle="Dados Pessoais"
        description="Gerencie seus dados e configurações do perfil"
        image="/specialist/doctor.png"
      />
      <div className="indicator col-span-12">
        <div className="card border">
          <div className="card-body">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  onClick={isGoogle.length > 0 ? () => toast.warn('Altere sua foto na conta do google') : () => fileInputRef.current.click()}
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    isGoogle.length > 0
                      ? isGoogle[0].identity_data.avatar_url
                      : avatar
                        ? `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${user?.id}/avatar/${avatar}`
                        : '/profile.png'
                  }
                  alt="avatar"
                />
                <input
                  type="file"
                  name="avatar"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => handleUploadAvatar(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <form>
          <div className="grid grid-cols-1 desktop:grid-cols-2 gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Nome</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Nome"
                className={`input input-bordered w-full  ${errors?.name?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name')}
              />
              <p className="text-error">{errors.name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Email</span>
              </label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                className={`input input-bordered w-full  ${errors?.email?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email')}
              />
              <p className="text-error">{errors.email?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Data de Nascimento
                </span>
              </label>
              <input
                type="text"
                name="date_of_birth"
                defaultValue={specialist[0]?.date_of_birth}
                placeholder="DD/MM/AAAA"
                className={`input input-bordered w-full  ${errors?.date_of_birth?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('date_of_birth', {
                  value: specialist[0]?.date_of_birth,
                })}
              />
              <p className="text-error">{errors.date_of_birth?.message}</p>
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">Gênero</div>
              </label>

              <select
                name="gender"
                className="select select-bordered w-full"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('gender')}
              >
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              <p className="text-error">{errors.gender?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Celular</span>
              </label>
              <input
                type="text"
                name="mobile_number"
                placeholder="(12) 9999-9999"
                className={`input input-bordered w-full  ${errors?.mobile_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('mobile_number')}
              />
              <p className="text-error">{errors.mobile_number?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Telefone</span>
              </label>
              <input
                type="text"
                name="telephone_number"
                placeholder="(12) 3333-3333"
                className={`input input-bordered w-full  ${errors?.telephone_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('telephone_number')}
              />
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">País</div>
              </label>

              <input
                type="text"
                name="country"
                placeholder="País"
                className={`input input-bordered w-full  ${errors?.country?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('country')}
              />
              <p className="text-error">{errors.country?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Estado</span>
              </label>
              <input
                type="text"
                name="state"
                placeholder="SP"
                className={`input input-bordered w-full  ${errors?.state?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('state')}
              />
              <p className="text-error">{errors.state?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Cidade</span>
              </label>
              <input
                type="text"
                name="city"
                defaultValue={specialist[0]?.city}
                placeholder="São Paulo"
                className={`input input-bordered w-full  ${errors?.city?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('city', { value: specialist[0]?.city })}
              />
              <p className="text-error">{errors.city?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Bairro</span>
              </label>
              <input
                type="text"
                name="neighborhood"
                defaultValue={specialist[0]?.neighborhood}
                placeholder="Consolação"
                className={`input input-bordered w-full  ${errors?.neighborhood?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('neighborhood', {
                  value: specialist[0]?.neighborhood,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Endereço</span>
              </label>
              <input
                type="text"
                name="street_name"
                defaultValue={specialist[0]?.street_name}
                placeholder="Avenida Paulista"
                className={`input input-bordered w-full  ${errors?.street_name?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('street_name', {
                  value: specialist[0]?.street_name,
                })}
              />
              <p className="text-error">{errors.street_name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Número</span>
              </label>
              <input
                type="text"
                name="house_number"
                defaultValue={specialist[0]?.house_number}
                placeholder="1500"
                className={`input input-bordered w-full  ${errors?.house_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('house_number', {
                  value: specialist[0]?.house_number,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">CEP</span>
              </label>
              <input
                type="text"
                name="zip_code"
                placeholder="11600-000"
                className={`input input-bordered w-full  ${errors?.zip_code?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('zip_code')}
              />
              <p className="text-error">{errors.zip_code?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">CPF</span>
              </label>
              <input
                type="text"
                id="cpf_number"
                name="cpf_number"
                placeholder="xxx.xxx.xxx-xx"
                className={`input input-bordered w-full  ${errors?.rg_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('cpf_number')}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">RG</span>
              </label>
              <input
                type="text"
                name="rg_number"
                placeholder="xx.xxx.xxx-x"
                className={`input input-bordered w-full  ${errors?.rg_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('rg_number')}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">CRP</span>
              </label>
              <input
                type="text"
                name="crp_number"
                placeholder="xx.xxx.xxx-x"
                className={`input input-bordered w-full  ${errors?.crp_number?.type === 'required' && 'input-error'
                  }`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('crp_number')}
              />
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">Especialidades</div>
              </label>
              <Select
                name="skills"
                value={selectedSkills}
                options={specialties}
                isMulti
                classNamePrefix="select"
                className="select-bordered"
                onChange={setSelectedSkills}
              />
              <p className="text-error">{errors.skills?.message}</p>
            </div>
            <div className="inline-block">
              <label className="label">
                <div className="label-text">Categoria</div>
              </label>
              
              <Select
                name="typeSession"
                options={[{ value: 'individual', label: 'Individual' }, { value: 'casal', label: 'Casal' }, { value: 'familia', label: 'família' }]}
                isMulti
               
                {...register('typeSession', {
                  value: specialist[0]?.typeSession,
                })}
                classNamePrefix="select"
                className="select-bordered "
                onChange={(event) => setValue('typeSession', event , { shouldValidate: true })}
              />
              <p className="text-error">{errors.typeSession?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <div className="label-text">Diploma</div>
              </label>
              <input
                type="file"
                name="certified"
                className={`file-input ${errors.certified?.message ? 'file-input-error' : ''
                  } file-input-bordered w-full`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('certified', {
                  onChange: (e) => handleUploadCertified(e),
                })}
              />
              <p className="text-error">{errors.certified?.message}</p>
              {media.map((item) => (
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-ghost"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${userId}/certified/${item.name}`,
                        '_blank',
                        'noreferrer',
                      )
                    }
                  >
                    Download - {item?.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <div className="label-text">Sobre você</div>
            </label>
            <textarea
              name="about"
              className="textarea textarea-bordered w-full"
              placeholder="Bio"
              {...register('about')}
            />
          </div>
          <div className="divider" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <div className="form-control w-52">
                <label className="cursor-pointer label">
                  <span className="label-text text-base-content">
                    Habilitar Perfil
                  </span>
                  <input
                    type="checkbox"
                    name="allow"
                    onClick={(event) => handleProfileDisable(event)}
                    className="toggle"
                    {...register('allow')}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="mt-16">
            <button
              type="button"
              onClick={() => handleSubmit(handleFormSubmit)()}
              className="btn btn-primary float-right"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;
