
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import usePayment from '../modules/patients/hooks/UsePayment';
import { useAuth } from '../shared/context/useAuth';

function Header() {
  const { pathPhotoAvatar, user, typeUser } = useAuth();
  const history = useHistory();
  const { amount } = usePayment();

  const isGoogle = user.identities.filter((item) => {
    return item.provider !== 'email';
  });

  function logoutUser() {
    localStorage.clear();
    window.location.href = `https://www.embraceinternacional.com/`;
  }

  const { avatar } = useSelector((state) => {
    return state.avatar;
  });

  return (
    <div className="navbar flex justify-between dark:bg-base-100 bg-white  z-10 shadow-sm ">
      <div className="">
        <label
          htmlFor="left-sidebar-drawer"
          className="btn btn-primary drawer-button laptop:hidden desktop:hidden"
        >
          <Bars3Icon className="h-5 inline-block w-5" />
        </label>
      </div>

      <div className="order-last">
        {/* saldo */}
        {typeUser === 'cli' && (
          <div className="dropdown dropdown-bottom dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="bg-[#3bbdcf] text-sm font-bold text-white rounded-xl p-1 m-1"
            >
              Crédito
            </div>
            <div
              tabIndex={0}
              className="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-white text-primary-content"
            >
              <div className="card-body text-[black]">
                <div className="">
                  <div className="stat-title text-[#666]">Seu saldo</div>
                  <div className="stat-value">${amount || '0'}</div>
                  <div className="stat-actions">
                    {amount < 49 ||
                      (!amount && (
                        <button
                          onClick={() => history.push('/pagamento/cli')}
                          className="btn btn-sm btn-success"
                        >
                          Adicionar saldo
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Profile icon, opening menu on click */}
        <div className="dropdown dropdown-end ml-4">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            {typeUser !== 'admin' ? (
              <div className="w-10 rounded-full">
                <img
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    isGoogle.length > 0
                      ? isGoogle[0].identity_data.avatar_url
                      : pathPhotoAvatar
                        ? `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${user?.id}/avatar/${avatar?.path?.split('/')[2] || pathPhotoAvatar}`
                        : '/profile.png'
                  }
                  alt="avatar"
                />
              </div>
            ) : (
              <span>Area admin</span>
            )}
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            {typeUser !== 'admin' ? (
              <>
                <li className="justify-between">
                  <Link
                    to={`/perfil/${window.location.pathname.split('/')[2]}`}
                  >
                    Editar Perfil
                  </Link>
                </li>
                <div className="divider mt-0 mb-0" />
              </>
            ) : (
              ''
            )}
            <li>
              <span onClick={logoutUser}>Sair</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
