import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from "react-ga4";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import store from './app/store';
import { supabase } from './data/supabase';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ModalLayout from './shared/components/ModalLayout/ModalLayout';
import { FilterProvider } from './shared/context/filterContext';
import { AuthProvider } from './shared/context/useAuth';


ReactGA.initialize("G-RVJE3T8YPF");

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FilterProvider>
  <Provider store={store}>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary fallback={<div>Algo deu errado, tente novamente.</div>}>
          <SessionContextProvider supabaseClient={supabase}>
            <ModalLayout />
            <App />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </SessionContextProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    </AuthProvider>
  </Provider>
  </FilterProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
