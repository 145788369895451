
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import getInitials from '../utils/nameInitial';
import { supabase } from './supabase';

async function handleMeeting(
  message,
  closeModal,
  user,
  priceCategory,
  balance,
) {
  const {
    data: { user: userPatient },
  } = await supabase.auth.getUser();

  const products = {
    49: 'Terapia individual',
    69: 'Terapia de Casal/Familia',
    245: 'Combo Individual',
    345: 'Combo Casal/Familia',
  };

  const { data: patient } = await supabase
    .from('patient')
    .select()
    .eq('id_user', userPatient.id);


  const endTime = moment(message.time, 'HH:mm')
    .add(50, 'minutes')
    .format('HH:mm');
    const startTime = moment(message.time, 'HH:mm').format('HH:mm');
    const dateDome = moment(message.selectedData).format('YYYY-MM-DD');

  const momentObjStart = moment(dateDome + startTime, 'YYYY-MM-DD HH:mm');
  const momentObjEnd = moment(dateDome + endTime, 'YYYY-MM-DD HH:mm');


  // Google Calendar
  const event = {
    start: {
      dateTime: momentObjStart,
    },
    end: {
      dateTime: momentObjEnd,
      // timeZone: moment.tz.guess(),
    },
    attendees: [
      {
        email: message.emailSpec,
      },
      {
        email: user.email,
      },
    ],
    summary: `Consulta com ${getInitials(patient[0].name)}`,
    describe: 'Terapia individual',
  };


 const link = await axios
    .post(
      `${process.env.REACT_APP_URL_BACKEND}/api/insert-event`,
      JSON.stringify(event),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then((res) => {
      return res.data?.events?.hangoutLink
    });



  const { error, data } = await supabase
    .from('appointment')
    .upsert({
      specialist_id: message.idSpec,
      patient_id: patient[0].id,
      status: 'confirmed',
      start: momentObjStart,
      end: momentObjEnd,
      start_history: momentObjStart,
      end_history: momentObjEnd,
      category: products[priceCategory],
      meetLink: link,
    })
    .select();

  if (error) {
    return toast.error('Algo deu errado, tente novamente!');
  }
  // Balance

  await supabase
    .from('balance')
    .update({
      amount: balance[0].amount - priceCategory,
      appointment_id: data[0]?.id,
    })
    .select('amount')
    .eq('patient_id', patient[0].id)
    .order('id', { ascending: false })
    .limit(1);

  message.setTimeMeeting(message.time);
  toast.success(
    <div>
      <p className="text-[20px]">Agendamento concluido</p>
    </div>,
  );
  closeModal();
  window.location.href = "/consultas/cli"
}

export default handleMeeting;
