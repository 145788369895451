import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { bool, object, string } from 'yup';
import { supabase } from '../../../../../data/supabase';
import TitleCardDefault from '../../../../../shared/components/Cards/TitleCardDefault';
import { useAuth } from '../../../../../shared/context/useAuth';
import { addAvatar } from '../../../../../store/reducers/avatar';
import usePatientProfile from '../../../hooks/UsePatientProfile';

const schemaForm = object({
  name: string().required('O campo é obrigatório.'),
  email: string().email('E-mail inválido.').required('O campo é obrigatório.'),
  phoneNumber: string().required('O campo é obrigatório.'),
  telephone_number: string().optional(),
  country: string().required('O campo é obrigatório.'),
  state: string().required('O campo é obrigatório.'),
  city: string().required('O campo é obrigatório.'),
  neighborhood: string().optional(),
  street_name: string().required('O campo é obrigatório.'),
  zip_code: string().required('O campo é obrigatório.'),
  allow: bool().optional(),
});

function PatientProfile() {
  const { user } = useAuth();
  const { patient, updateProfile, getMedia } = usePatientProfile();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaForm) });
  const fileInputRef = useRef();
  const [avatar, setAvatar] = useState();

  const isGoogle = user.identities.filter((item) => {
    return item.provider !== 'email';
  });

  const getAvatar = async () => {
    const { data } = await supabase.storage
      .from('uploads')
      .list(`${user.id}/avatar/`, {
        limit: 1,
        offset: 0,
        sortBy: { column: 'updated_at', order: 'desc' },
      });

    if (data && data?.length > 0) {
      setAvatar(data[0].name);
    }
  };

  useEffect(() => {
    getMedia();
    getAvatar();
    // reset form with patient data
    reset(...patient);
  }, [patient]);

  const dispatch = useDispatch();

  const handleFormSubmit = (data) => {
    updateProfile(data);
  };

  const handleUploadAvatar = async (event) => {
    const file = event.target.files[0];

    const { data } = await supabase.storage
      .from('uploads')
      .upload(`${patient[0]?.id_user}/avatar/${file?.name}`, file, {
        upsert: true,
      });

    if (data) {
      setAvatar(data?.path?.split('/')[2]);
      dispatch(addAvatar({ data }));
      toast.success('Foto de perfil atualizado');
    }
  };

  return (
    <div className="card w-full p-6 col-span-12 gap-6 bg-base-100 shadow-xl">
      <TitleCardDefault
        title="Perfil do Paciente"
        subtitle="Dados Pessoais"
        description="Gerencie seus dados e configurações do perfil"
      />
      <div className="indicator col-span-12">
        <div className="card border">
          <div className="card-body">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  onClick={
                    isGoogle.length > 0
                      ? () => toast.warn('Altere sua foto na conta do google')
                      : () => fileInputRef.current.click()
                  }
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    isGoogle.length > 0
                      ? isGoogle[0].identity_data.avatar_url
                      : avatar
                        ? `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${user?.id}/avatar/${avatar}`
                        : '/profile.png'
                  }
                  alt="avatar"
                />
                <input
                  type="file"
                  name="avatar"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={(e) => handleUploadAvatar(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <form>
          <div className="grid grid-cols-1 desktop:grid-cols-2 gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Nome Completo
                </span>
              </label>
              <input
                type="text"
                name="name"
                className={`input input-bordered w-full  ${errors?.name?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('name')}
              />
              <p className="text-error">{errors.name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Email</span>
              </label>
              <input
                type="text"
                name="email"
                className={`input input-bordered w-full  ${errors?.email?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('email')}
              />
              <p className="text-error">{errors.email?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Celular</span>
              </label>
              <input
                type="text"
                name="mobile_number"
                className={`input input-bordered w-full  ${errors?.mobile_number?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                value={user?.user_metadata.mobile_number || ''}

              />
              <p className="text-error">{errors.mobile_number?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Telefone</span>
              </label>
              <input
                type="text"
                name="telephone_number"
                className={`input input-bordered w-full  ${errors?.telephone_number?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('telephone_number')}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Endereço</span>
              </label>
              <input
                type="text"
                name="street_name"
                defaultValue={patient[0]?.street_name}
                className={`input input-bordered w-full  ${errors?.street_name?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('street_name', { value: patient[0]?.street_name })}
              />
              <p className="text-error">{errors.street_name?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Complemento
                </span>
              </label>
              <input
                type="text"
                name="neighborhood"
                defaultValue={patient[0]?.neighborhood}
                className={`input input-bordered w-full  ${errors?.neighborhood?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('neighborhood', {
                  value: patient[0]?.neighborhood,
                })}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Cidade</span>
              </label>
              <input
                type="text"
                name="city"
                defaultValue={patient[0]?.city}
                className={`input input-bordered w-full  ${errors?.city?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('city', { value: patient[0]?.city })}
              />
              <p className="text-error">{errors.city?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">Estado</span>
              </label>
              <input
                type="text"
                name="state"
                className={`input input-bordered w-full  ${errors?.state?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('state')}
              />
              <p className="text-error">{errors.state?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">
                  Código Postal
                </span>
              </label>
              <input
                type="text"
                name="zip_code"
                className={`input input-bordered w-full  ${errors?.zip_code?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('zip_code')}
              />
              <p className="text-error">{errors.zip_code?.message}</p>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-base-content">País</span>
              </label>
              <input
                type="text"
                name="country"
                className={`input input-bordered w-full  ${errors?.country?.type === 'required' && 'input-error'}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('country')}
              />
              <p className="text-error">{errors.country?.message}</p>
            </div>
          </div>
          <div className="divider" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <div className="form-control w-52">
                <label className="cursor-pointer label">
                  <span className="label-text text-base-content">
                    Habilitar Perfil
                  </span>
                  <input
                    type="checkbox"
                    name="allow"
                    className="toggle"
                    {...register('allow')}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="mt-16">
            <button
              type="button"
              onClick={() => handleSubmit(handleFormSubmit)()}
              className="btn btn-primary float-right"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PatientProfile;
