import CookieConsent from 'react-cookie-consent';
import Header from '../../../containers/Header';
import LeftSidebar from '../../../containers/LeftSidebar';

function LayoutWrapper({ children }) {
  return (
    <div className="drawer drawer-mobile">
      <input
        id="left-sidebar-drawer"
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="drawer-content overflow-y-hidden flex flex-col bg-[#F4F7FB] dark:bg-base-200">
        <Header />
        <main className="mt-12 p-4 max-w-7xl w-full mx-auto desktop:my-0">
          {children}
        </main>
      </div>
      <LeftSidebar />
      <CookieConsent
        location="bottom"
        buttonText="Aceitar Cookies"
        declineButtonText="Rejeitar"
        cookieName="EmbraceCookieConsent"
        style={{
          background: '#f9fafb',
          color: '#4b5563',
          padding: '2rem 1rem',
        }}
        overlay
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        <p className="col-span-6">
          Nós e os terceiros selecionados usamos cookies ou tecnologias
          similares para finalidades técnicas e, com seu consentimento, para
          outras finalidades conforme especificado na política de cookie. Use o
          botão “Aceitar” para consentir.
        </p>
      </CookieConsent>
    </div>
  );
}
export default LayoutWrapper;
