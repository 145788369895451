import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../shared/context/useAuth';
import usePatientProfile from '../../../hooks/UsePatientProfile';

export default function Checkout() {
  const [prices, setPrices] = useState([]);
  const { patient } = usePatientProfile();
  const { session } = useAuth()

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_BACKEND}/api/products`)
      .then((response) => setPrices(response.data.data))
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function currencyFormat(num) {
    return `$${num / 100}`;
  }

  console.log('EMAIL', session?.user?.email);
  const handleCheckout = (priceId) => {
    axios
      .post(
        `${process.env.REACT_APP_URL_BACKEND}/api/create-checkout-session`,
        JSON.stringify({
          priceId,
          user: { name: patient[0]?.name, email: session?.user?.email },
        }),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(async (response) => {
        const body = await response.data;
        window.location.href = body.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterPrices = prices?.filter((item) => item.product.active === true && item.active === true )


  return (
    <div className="mx-auto col-span-12">
      <div className="flex flex-col gap-4 desktop:flex-row items-center justify-center">
        {filterPrices?.reverse()?.map((price) => {
          return (
            <div className="md:w-1/3 p-6 rounded-lg border">
              <h2 className="text-lg font-bold mb-2">{price.product.name}</h2>
              <p className="text-gray-700 mb-4">{price.product.name}</p>
              <p className="text-4xl font-bold mb-4">
                {currencyFormat(price.unit_amount)}
              </p>
              <ul className="text-sm text-gray-700">
                <li>Vídeo conferência</li>
                <li>Sala Individual</li>
              </ul>
              <button
                onClick={() => handleCheckout(price.id)}
                className="text-sm desktop:text-[14px] desktop:py-2 desktop:px-5 px-[64px] py-[12px] mt-11 mb-5 text-white font-bold p-[24px] bg-[#3BBED0] border-0 rounded-[5px]"
              >
                Comprar
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
