import Select from 'react-select';





export const MultipleSelectChip = ({ title, onChange }) => {
  const categories = [{ label: 'Individual' }, { label: 'Casal' }, { label: 'Familia' }]

  return (
    <div>
      <form>
        <label id="demo-multiple-chip-label " className='font-semibold text-[#666]'>{title}</label>
        <Select
          classNamePrefix="select"
          className="basic-single mt-2"
          onChange={onChange}
          options={categories}
        />

      </form>
    </div>
  );
}