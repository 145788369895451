/* eslint-disable import/no-extraneous-dependencies */
import { useQuery } from '@tanstack/react-query';
import getSpecialist from '../../../../data/specialists';
import CardSpecialist from '../../components/CardSpecialist';

const Specialists = () => {
  const {
    data: specialists,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['specialists'],
    queryFn: getSpecialist,
  });

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  return (
    <>
      <div className="w-full col-span-12 mb-8">
        <div className="relative p-6 rounded-2xl bg-[#3bbdcf] text-primary-content row-start-1 row-end-2 col-span-3 md:col-span-3 lg:col-span-4">
          <h2 className="font-bold text-2xl mb-6">Especialistas disponíveis</h2>
          <p>Acesse o perfil de cada especialista e veja sua disponibilidade</p>
          <img
            src="/common/pro2.png"
            alt="doctor"
            className="absolute bottom-0 right-7 mobile:hidden"
            width={160}
          />
        </div>
      </div>
      {error && <p>{error}</p>}
      {specialists && <CardSpecialist specialistData={specialists} />}
    </>
  );
};

export default Specialists;
